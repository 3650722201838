import classes from './Projects.module.css'

const Projects = () => {
	return (
		<div className={classes.projects}>
			<p>Kilka innych moich projektów:</p>
			<a href='https://blog-pied-zeta.vercel.app/' target="'_blank" rel='noreferrer'>
				Blog
			</a>
			<p>Prosty blog (React, Redux, Router) z komunikacja z baza danych (REST API), logowaniem i walidacja</p>
			<a href='https://to-do-app-lovat-two.vercel.app/' target="'_blank" rel='noreferrer'>
				ToDo App
			</a>
			<p>Prosta aplikacja z dodawaniem zadań do wykonania i możliwościa usuwania ich</p>
			<a href='https://password-generator-zeta-rouge.vercel.app/' target="'_blank" rel='noreferrer'>
				Generator haseł
			</a>
			<p>Generator losowych haseł z możliwościa wybrania rodzaju i długości hasła</p>
			<a href='https://github.com/kaweckimarcin' target="'_blank" rel='noreferrer'>
				Zobacz mój GitHub
			</a>
			<p className='type'>&nbsp;</p>
		</div>
	)
}

export default Projects
