import classes from './HomePage.module.css'
import MarcinKawecki from '../img/MarcinKawecki.png'
const HomePage = () => {
	return (
		<div className={classes.homePage}>
			<img src={MarcinKawecki} alt='Marcin-Kawecki.pl' className={classes.logo} />
			<p className={classes.type1}>Cześć!</p>
			<p className={classes.type2}>Nazywam sie Marcin</p>
			<p className={classes.type3}>Chce zostać web developerem</p>
			<p className={classes.type4}>Poznajmy sie lepiej!</p>
		</div>
	)
}

export default HomePage
