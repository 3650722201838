import { Link } from 'react-router-dom'
import classes from './Navbar.module.css'

function Navbar() {
	return (
		<header className={classes.header}>
			<div className={classes.navigation}>
				<Link to='/'>
					<p className={classes.logo}>Marcin-Kawecki.pl</p>
				</Link>
				<nav>
					<ul>
						<li>
							<Link to='/o-mnie'>O mnie</Link>
						</li>
						<li>
							<Link to='/strony'>Strony</Link>
						</li>
						<li>
							<Link to='/projekty'>Projekty</Link>
						</li>
						<li>
							<Link to='/kontakt'>Kontakt</Link>
						</li>
					</ul>
				</nav>
			</div>
		</header>
	)
}

export default Navbar
