import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'
import Layout from './components/ui/Layout'
import HomePage from './components/HomePage'
import About from './components/About'
import Pages from './components/Pages'
import Projects from './components/Projects'
import Contact from './components/Contact'

import classes from './App.module.css'

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path='/' element={<Layout />}>
			<Route index element={<HomePage />} />
			<Route path='/o-mnie' element={<About />} />
			<Route path='/strony' element={<Pages />} />
			<Route path='/projekty' element={<Projects />} />
			<Route path='/kontakt' element={<Contact />} />
		</Route>
	)
)

function App() {
	return (
		<div className={classes.app}>
			<RouterProvider router={router} />
		</div>
	)
}

export default App
