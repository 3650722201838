import { useState } from 'react'
import { Link } from 'react-router-dom'
import classes from './Menu.module.css'

function Menu() {
	const [burger, setBurger] = useState(false)

	const buttonHandler = () => {
		setBurger(prevState => !prevState)
	}

	return (
		<header className={classes.header}>
			<div className={classes.button}>
				<button onClick={buttonHandler}>{burger ? 'ZAMKNIJ' : 'MENU'}</button>
			</div>
			<div className={`${classes.nav} ${burger ? classes.active : ''}`}>
				<nav>
					<ul>
						<li>
							<Link onClick={buttonHandler} to='/'>
								Strona główna
							</Link>
						</li>
						<li>
							<Link onClick={buttonHandler} to='/o-mnie'>
								O mnie
							</Link>
						</li>
						<li>
							<Link onClick={buttonHandler} to='/strony'>
								Strony
							</Link>
						</li>
						<li>
							<Link onClick={buttonHandler} to='/projekty'>
								Projekty
							</Link>
						</li>
						<li>
							<Link onClick={buttonHandler} to='/kontakt'>
								Kontakt
							</Link>
						</li>
					</ul>
				</nav>
			</div>
		</header>
	)
}

export default Menu
