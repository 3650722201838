import classes from './Pages.module.css'

const Pages = () => {
	return (
		<div className={classes.pages}>
			<p>Strony, nad którymi pracuje:</p>
			<a href='https://marcin-kawecki.pl'>Marcin-Kawecki.pl</a>
			<a href='https://marcin-kawecki.pl/serdeczniezapraszam/' target="'_blank" rel='noreferrer'>
				Serdecznie Zapraszam
			</a>
			<p className='type'>&nbsp;</p>
		</div>
	)
}

export default Pages
