import Navbar from './Navbar'
import classes from './Layout.module.css'
import { Outlet } from 'react-router'
import Menu from './Menu'

const Layout = () => {
	return (
		<>
			<div className={classes.navbar}>
				<Navbar />
			</div>
			<div className={classes.menu}>
				<Menu />
			</div>
			<main className={classes.layout}>
				<div className={classes.container}>
					<Outlet />
				</div>
			</main>
		</>
	)
}

export default Layout
