import classes from './Contact.module.css'

const Contact = () => {
	return (
		<div className={classes.contact}>
			<p>Kontakt:</p>
			<p>Marcin Kawecki</p>
			<p>E-mail:</p>
			<a href='mailto:kaweckimarcin1@gmail.com'>kaweckimarcin1@gmail.com</a>
			<p>Telefon:</p>
			<a href='tel:500317220'>500 317 220</a>
			<p>Github:</p>
			<a href='https://github.com/kaweckimarcin' target='_blank' rel='noreferrer'>
				Zobacz profil
			</a>
			<p className='type'>&nbsp;</p>
		</div>
	)
}

export default Contact
