import classes from './About.module.css'

const About = () => {
	return (
		<div className={classes.about}>
			<p>Moje umiejetności to miedzy innymi:</p>

			<h6>HTML, CSS, JavaScript</h6>
			<p>(podstawa front-endu)</p>

			<h6>ReactJS, Router, Redux</h6>
			<p>(biblioteki, bez których nie ma mowy o nowoczesnej stronie www)</p>

			<h6>NextJS</h6>
			<p>(dopiero ucze sie tego frameworka, ale już znam solidne podstawy!)</p>

			<h6>TypeScript</h6>
			<p>(tu także jestem na etapie nauki)</p>
			<p className='type'>&nbsp;</p>
		</div>
	)
}

export default About
